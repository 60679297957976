<template>
  <div>
    <TableTransaction :url="url" />
  </div>
</template>
<script>
import TableTransaction from "src/components/TableTransaction.vue";
export default {
  components: {
    TableTransaction,
  },

  data() {
    return {
      url: this.$hostnameasg,
    };
  },
};
</script>
<style>
.el-table tr:last-child td {
  background-color: #f5f7fa !important;
}
</style>
